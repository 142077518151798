@font-face {
    font-family: 'icomoon';
    src: url('./assets/fonts/icomoon.eot?vdgxia');
    src: url('./assets/fonts/icomoon.eot?vdgxia#iefix') format('embedded-opentype'),
        url('./assets/fonts/icomoon.ttf?vdgxia') format('truetype'),
        url('./assets/fonts/icomoon.woff?vdgxia') format('woff'),
        url('./assets/fonts/icomoon.svg?vdgxia#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

* {
    box-sizing: border-box;
}

body {
    color: #fff;
    margin: 0;
    padding: 0;
}

body,
p,
a,
b,
i,
h1,
h2,
h3 {
    font-family: 'Open Sans', sans-serif;
}

.app {
    display: flex;
    background: url('./assets/background.jpg');
    width: 100%;
    margin: 0 auto;
    height: 100vh;
    background-size: cover;
    overflow: hidden;
    flex-direction: column;
}

@media only screen and (min-width: 992px) {
    .app {
        max-width: 400px;
    }
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-php-elephant:before {
    content: "\e900";
}

.icon-react:before {
    content: "\e903";
    color: #61dafb;
}

.icon-amazonaws:before {
    content: "\e901";
}

.icon-node-dot-js:before {
    content: "\e902";
    color: #393;
}

.icon-twitter:before {
    content: "\ea96";
}

.icon-github:before {
    content: "\eab0";
}

.icon-wordpress:before {
    content: "\eab4";
}

.icon-linkedin:before {
    content: "\eac9";
}