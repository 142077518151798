.avatar {
    width: 200px;
    height: 200px;
    background: #cccccc;
    display: flex;
    margin: 50px auto;
    border-radius: 50%;
    justify-content: center;
    align-items: center;

    .avatar-inner{
        width: 190px;
        height: 190px;
        background: #fff;
        display: block;
        border-radius: 50%;

        img { display: block; }
    }
}